import React from "react"
import { InstantSearch, Configure, connectHits } from "react-instantsearch-dom"
// import { navigate } from "gatsby"

// import * as R from "@constants/routes"
import * as C from "@constants"
import { useAlgolia, useTranslation } from "@hooks"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import UserArtist from "@components/UserArtist"
import UserStudio from "@components/UserStudio"
import UserCollector from "@components/UserCollector"

const User = ({ hits }) => {
  const user = hits[0]

  if (!user) {
    // if (typeof window !== "undefined") {
    //   return navigate(R.NOT_FOUND)
    // }
    return null
  }

  const [t] = useTranslation()
  const title = user.username || t("pages.User.seo.title")
  const description = t("pages.User.seo.description")

  if (user.role === C.USERS_ROLE_ARTIST) {
    return (
      <>
        <SEO title={title} description={description} />
        <UserArtist user={user} />
      </>
    )
  } else if (user.role === C.USERS_ROLE_STUDIO) {
    return (
      <>
        <SEO title={title} description={description} />
        <UserStudio user={user} />
      </>
    )
  } else {
    return (
      <>
        <SEO title={title} description={description} />
        <UserCollector user={user} />
      </>
    )
  }
}

const ConnectedUser = connectHits(User)

const UserPage = ({ username }) => {
  const searchClient = useAlgolia()
  return (
    <Layout>
      <InstantSearch indexName={C.INDEX_USERS} searchClient={searchClient}>
        <Configure filters={`username:"${username}"`} hitsPerPage={1} />
        <ConnectedUser />
      </InstantSearch>
    </Layout>
  )
}

export default UserPage
